
























import { Component, Vue } from "vue-property-decorator"
import topNav from "../../components/topNav.vue"

import 'vant/lib/index.css';
import { Step, Steps } from 'vant';

@Component({
	components: {
		topNav,
    [Step.name]:Step,
    [Steps.name]:Steps,
	}
})
export default class Default extends Vue {
  receiverAddressTitle: string = "物流信息"
  orderLogistics:any={}
  async created() {
    let id=this.$route.query.id;
    let res:any=await this.getOrderDetailInfo(id);
    let orderLogistics=res.data[0].getFuaEsbOrder.orderLogistics;
    this.orderLogistics=orderLogistics;
    console.log(this.orderLogistics,'res');
  }
  getOrderDetailInfo(id:any) {
    return new Promise((resolve)=>{
      this.$api.getOrderDetailUI(id).then((res:any) =>{
        resolve(res)
      })
    })
  }
}

